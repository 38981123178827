import React from 'react';
import './post.styl';

// Components
import { Link } from 'gatsby';
import PostMetadata from 'components/Post-Metadata';

// Utilities
import styled from '@emotion/styled';
import { propTypes } from 'shapes/post';

const Article = styled.article`
    font-size: 1.3rem;
`;

const Post = ({
    post: {
        frontmatter,
        html,
        wordCount: { words },
        timeToRead,
    },
}) => {
    const { date, path, title } = frontmatter;

    return (
        <Article className="post-container pb-2">
            <h2 className="post-title">
                <Link to={path}>{title}</Link>
            </h2>
            <PostMetadata date={date} words={words} timeToRead={timeToRead} />
            <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: html }}
            />
        </Article>
    );
};

Post.propTypes = propTypes;

export default Post;
