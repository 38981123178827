import React from 'react';

// Utilities
import { propTypes } from 'shapes/posts';
import { graphql } from 'gatsby';

// Components
import Layout from 'components/Layout';
import Post from 'components/Post';
import { Link } from 'gatsby';

const IndexPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const Posts = edges
        .filter(edge => !!edge.node.frontmatter.date)
        .map(({ node }) => <Post key={node.frontmatter.path} post={node} />);

    return (
        <Layout>
            <div className="posts-page">
                <div>{Posts}</div>
                <Link
                    to="/posts/2"
                    className="btn btn-outline-primary float-right"
                    href="#"
                    role="button"
                >
                    Next Page
                </Link>
            </div>
        </Layout>
    );
};

IndexPage.propTypes = propTypes;

export default IndexPage;

export const pageQuery = graphql`
    {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { published: { ne: false } } }
            limit: 5
        ) {
            edges {
                node {
                    html
                    timeToRead
                    excerpt
                    wordCount {
                        words
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                        tags
                    }
                }
            }
        }
    }
`;
